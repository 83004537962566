import React from 'react';
import "./HomePage.css";

// import BlueIcon from "../BlueIcon/BlueIcon";


import DescriptionBanner from '../Banners/DescriptionBanner';




class HomePage2 extends React.Component {
  render() {
    return (
      <div>
        {/* banner image */}
        
        <DescriptionBanner/>
        
        
        
      </div>
    );
  }
}

export default HomePage2;